import React from 'react';
import Mobile from '../components/icons/mobile';
import Email from '../components/icons/email';
import Content from '../components/content';
import { data } from '../data/data';
import '../styles/footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <Content>
        <ul className="contacts-details">
          <li>
            <Mobile />
            <a href={`tel:${data.phone.cecoPrefix}`}>{data.phone.ceco}</a>
          </li>
          <li>
            <Mobile />
            <a href={`tel:${data.phone.miroPrefix}`}>{data.phone.miro}</a>
          </li>
          <li className="email-area">
            <Email />
            <a href={`mailto:${data.email}`}>{data.email}</a>
          </li>
        </ul>
        <div className="copyright">
          &copy; {new Date().getFullYear()} All rights reserved. Created by:{' '}
          <a href="https://pantaley.com/" target="_blank" rel="noopener noreferrer">
            {' '}
            pantaley.com
          </a>
        </div>
      </Content>
    </footer>
  );
};

export default Footer;
