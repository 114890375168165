export const data = {
  phone: {
    ceco: '0886 35 09 27',
    cecoPrefix: '+359886350927',
    miro: '0889 57 03 67',
    miroPrefix: '+359889570367'
  },
  email: 'office@prstroy.eu'
};

export const homePageShowcase = [
  {
    name: 'Административна сграда - ПЕЕ Интернешънъл',
    image: '01-Административна сграда - ПЕЕ Интернешънъл.jpg',
    url: '/%D0%BD%D0%BE%D0%B2%D0%BE-%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D0%B5%D0%BB%D1%81%D1%82%D0%B2%D0%BE/%D0%90%D0%B4%D0%BC%D0%B8%D0%BD%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%82%D0%B8%D0%B2%D0%BD%D0%B0%20%D1%81%D0%B3%D1%80%D0%B0%D0%B4%D0%B0%20-%20%D0%9F%D0%95%D0%95%20%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%88%D1%8A%D0%BD%D1%8A%D0%BB'
  },
  {
    name: 'Цех за производство на хартиени опаковки',
    image: '01-Цех за производство на хартиени опаковки.jpg',
    url: '/%D0%BD%D0%BE%D0%B2%D0%BE-%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D0%B5%D0%BB%D1%81%D1%82%D0%B2%D0%BE/%D0%A6%D0%B5%D1%85%20%D0%B7%D0%B0%20%D0%BF%D1%80%D0%BE%D0%B8%D0%B7%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE%20%D0%BD%D0%B0%20%D1%85%D0%B0%D1%80%D1%82%D0%B8%D0%B5%D0%BD%D0%B8%20%D0%BE%D0%BF%D0%B0%D0%BA%D0%BE%D0%B2%D0%BA%D0%B8'
  },
  {
    name: 'Марково Вила Парк - ВИП къщи',
    image: '01-Марково Вила Парк - ВИП къщи.jpg',
    url: '/%D0%BD%D0%BE%D0%B2%D0%BE-%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D0%B5%D0%BB%D1%81%D1%82%D0%B2%D0%BE/%D0%9C%D0%B0%D1%80%D0%BA%D0%BE%D0%B2%D0%BE%20%D0%92%D0%B8%D0%BB%D0%B0%20%D0%9F%D0%B0%D1%80%D0%BA%20-%20%D0%92%D0%98%D0%9F%20%D0%BA%D1%8A%D1%89%D0%B8'
  }
];
