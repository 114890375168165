import React, { StrictMode } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import '../styles/layout.scss';
import 'normalize.css';

import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          excerpt
          siteUrl
        }
      }
    }
  `);
  const metaData = site.siteMetadata;
  return (
    <StrictMode>
      <Helmet>
        <html lang="en" />
        <title>{`${metaData.title} | ${site.siteMetadata.title}`}</title>
        {/* <link rel="shortcut icon" href={favicon} type="image/png" /> */}

        <meta property="og:title" content={`${metaData.title} | ${site.siteMetadata.title}`} />
        <meta property="og:description" content={metaData.excerpt} />
        {/* <meta property="og:image" content={metaData.featuredImage ? metaData.featuredImage.publicURL : featuredImage} /> */}

        <meta name="description" content={metaData.excerpt} />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </StrictMode>
  );
};

export default Layout;
