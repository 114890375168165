import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Content from '../components/content';

import '../styles/header.scss';

const ListLink = ({ to, children }) => (
  <li>
    <Link to={to}>{children}</Link>
  </li>
);

const Header = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.png" }) {
        ...logoImage
      }
    }
  `);
  return (
    <header className="header">
      <Content type="wide">
        <Link className="wrapper" to="/">
          <GatsbyImage className="logo" image={getImage(logo)} alt="ПР Строй ООД" />
          <div className="slogan">Строителство и ремонт на сгради и съоръжения</div>
          <div className="company-name">ПР Строй ООД</div>
        </Link>
        <nav className="nav">
          <ul>
            <ListLink to="/обекти">Обекти</ListLink>
            {/* <ListLink to="/услуги">Услуги</ListLink> */}
            <ListLink to="/за-нас">За нас</ListLink>
            <ListLink to="/контакти">Контакти</ListLink>
          </ul>
        </nav>
      </Content>
    </header>
  );
};

export default Header;
